import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Paper, Typography } from "@mui/material";
import { Schedule } from "./types";
import ExpandMoreIcon from '@mui/icons-material/KeyboardArrowDown';


interface ScheduleViewerProps {
    result: Schedule;
    isLoading: boolean;
}

export const ScheduleViewer = ({result: data, isLoading}: ScheduleViewerProps) => {
    return (
        <div className="content">
            {isLoading ? <div className="content_loading"><CircularProgress /></div> : 
             <div className="content-list">
             {data.map(({date, data}) => {
                 const noData = Object.keys(data).length === 0
                 if (noData) {
                    return (
                        <h2 className={"date_no-data date"}>{date}</h2>
                    )
                 }
                 return (
                     <Paper elevation={3} >
                        <div className="day_with-appointments">
                        <h2 className={"date"}>{date}</h2>
                         <div className="day-styling">
                             {Object.keys(data).map((time: any) => (
                                   
                                   <Accordion>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        >
                                                <Typography>{time} ({data[time].length})</Typography>
                                            
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <ul>
                                            {data[time].map((name) => (
                                                <li>{name}</li>))}
                                            </ul>
                                        
                                        </AccordionDetails>
                                    </Accordion>
                                     
                             
                             ))}
                         </div>
                        </div>
                        
                     </Paper>
                 );
             })}
         </div>
            }
        </div>
    )
}
