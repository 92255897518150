export enum Article {
    MASSAGE_25 = 'massage25',
    MASSAGE_50 = 'massage50'
}

export const articleIds: Record<Article, number> = {
    [Article.MASSAGE_25]: 2101,
    [Article.MASSAGE_50]: 2102
}

export const articleNames: Record<Article, string> = {
    [Article.MASSAGE_25]: 'Klassische Massage - 25 min',
    [Article.MASSAGE_50]: 'Klassische Massage - 50 min'
}