export const incrementDate = (oldDate: Date, incr: number) => {
    const date = new Date(oldDate)
    date.setDate(date.getDate() + incr);
    return date;
}

export const getDates = (startDate: Date, stopDate: Date): Date[] => {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date (currentDate));
        currentDate = incrementDate(currentDate, 1);
    }
    return dateArray;
}