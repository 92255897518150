import { FormControlLabel, Switch, FormControl, InputLabel, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip } from "@mui/material"
import { Article, articleIds, articleNames } from "./articleIds";

interface SettingsProps {
    lazyMode: boolean;
    setLazyMode: (lazyMode: boolean) => void;
    article: Article;
    setArticle: (article: Article) => void;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const Settings = ({lazyMode, setLazyMode, article, setArticle, open, setOpen}: SettingsProps) => {
    console.log({lazyMode})
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogTitle>Settings</DialogTitle>
            <DialogContent>
                <div className="settings">
                <FormControlLabel control={<Switch checked={lazyMode} size="small" onChange={() => setLazyMode(!lazyMode)} />} label="Show only available" />
            <FormControl>
                <InputLabel id="article-select-label">Article</InputLabel>
                <Select
                    labelId="article-select-label"
                    value={article}
                    label="Article"
                    onChange={(e) => setArticle(e.target.value as Article)}
                    size="small"
                >
                    {Object.values(Article).map(article => {
                        return <MenuItem value={article}>{articleNames[article]}</MenuItem>
                    })}
                </Select>
                </FormControl>
                </div>
            
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
        
    )
}