import { Button, IconButton, Typography } from "@mui/material"
import { useState } from "react"
import { Article, articleNames } from "./articleIds"
import { Settings } from "./Settings"
import SettingsIcon from '@mui/icons-material/Settings';


interface HeaderProps {
    lazyMode: boolean
    setLazyMode: (lazyMode: boolean) => void
    article: Article
    setArticle: (article: Article) => void
}

export const Header = ({lazyMode,setLazyMode, article, setArticle}: HeaderProps) => {
    const [open, setOpen] = useState(false);
    return (
        <div className="header">
            <div className="header-first-line">
                <Typography variant="h5">{articleNames[article]}</Typography>
                <IconButton onClick={() => setOpen(true)}>
                    <SettingsIcon />
                </IconButton>
            </div>
           <div className="header-second-line">          
              <Button variant="outlined" size="small" target='_blank' href='https://www.vabali.de/berlin/massage-reservierung/'>Go to Booking</Button>


           </div>
            
            
            <Settings lazyMode={lazyMode} setLazyMode={setLazyMode} article={article} setArticle={setArticle} 
                open={open}
                setOpen={setOpen}/>
        </div>
        
    )
}