import { Button } from "@mui/material";
import { incrementDate } from "./getDates";

interface PaginationProps {
    setDate: (date: Date) => void;
    date: Date;
    hidePagination: boolean;
}

export const Pagination = ({date, setDate, hidePagination}: PaginationProps) => {
  const today = new Date();
  const isInFuture = date > new Date()
  console.log({date, today, isInFuture})
  
  return (
        <div className="footer-pagination">
            {!hidePagination && <div>
                <Button variant="outlined" size="small" disabled={!isInFuture} onClick={() => setDate(incrementDate(date, -7))}>Previous Week</Button>
            <Button variant="outlined" size="small" disabled={!isInFuture} onClick={() => setDate(today)}>Today</Button>
            <Button variant="outlined" size="small" onClick={() => setDate(incrementDate(date, 7))}>Next Week</Button>
            </div>}
        </div>
  );
};