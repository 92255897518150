
const dayOfWeek = ["Su", "Mo", "Tue", "We", "Thu", "Fri", "Sa"]
export const getDayOfWeek = (date: Date): string => dayOfWeek[date.getDay()]

export const format = (date: Date): string => {
    var dateObj = new Date(date);
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    return `${year}-${twoDigits(month)}-${twoDigits(day)}`
 }

const twoDigits = (n: number): string => {
    return n < 10 ? `0${n}`: '' + n
}
  