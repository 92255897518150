export const Footer = () => {
  
  return (
    <div className={"footer"}>
      <div className="footer-greetings">
  <span>
            Should there be any questions or complains don't hesitate to contact me at <a href="mailto:development+svmaf@simplelogin.co">development+svmaf@simplelogin.co</a></span>
</div>
        </div>
  );
};