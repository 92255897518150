import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';
import { Article } from './articleIds';
import { Footer } from './Footer';
import { format } from './formatDate';
import { Header } from './Header';
import { ScheduleViewer } from './ScheduleViewer';
import './App.css';
import { Pagination } from './Pagination';

function App() {
  const urlString = process.env.REACT_APP_API_ENDPOINT
  if (!urlString) {
    throw new Error("API_ENREACT_APP_API_ENDPOINTDPOINT is not set")
  }
  const [lazyMode, setLazyMode] = useState(true)
  const [article, setArticle] = useState<Article>(Object.values(Article)[0])
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [date, setDate] = useState(new Date());
        
  useEffect(() => {
    const api = async () => {
      setIsLoading(true)
      var url = new URL(urlString);
      url.searchParams.append("mode", lazyMode ? "lazy" : "normal");
      url.searchParams.append("article", article);
      url.searchParams.append("day", format(date));
      
      const data = await fetch(url.toString(), {
        method: "GET"
      });
      const jsonData = await data.json();
      setResult(jsonData);
      setIsLoading(false)
    };

    api();
  }, [lazyMode, article, date]);

  return (
    <div className="App">
      <Header lazyMode={lazyMode} setLazyMode={setLazyMode} article={article} setArticle={setArticle}/>
      <Pagination hidePagination={lazyMode} date={date} setDate={setDate}/>
      <ScheduleViewer isLoading={isLoading} result={result} />
      <Footer />
    </div>
  );
}

export default App;
